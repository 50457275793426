import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import * as APIS from "../../services/apis";
import { routes } from "@/routes";
import { useDispatch } from "react-redux";
import { showToast } from "@/features/toast/toastSlice";
import { STORAGE_KEY } from "@/utils/constants";
import { decodeJwt, postMessage } from "@/utils/utils";
import { signIn } from "@/utils/sign";
import { initSignUp, updateSignUp } from "@/features/auth/signUpSlice";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";

interface LocationState {
  errorType?: string;
  leaveDate?: string;
  reSignupDate?: string;
}

interface AppsFlyerParam {
  event: string;
  value?: { customer_user_id: string };
  userId?: number;
}

export default function AuthGateway() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search.replace(/&amp;/gi, "&"));
  const code = params.get("code") || "";
  const type = params.get("type") || "";
  const purpose = params.get("purpose") || "";
  const name = params.get("name") || "";
  const requestId = params.get("requestId") || "";
  const requireCompleteAction = params.get("requireCompleteAction") || "";
  const error = params.get("error") || "";
  const message = params.get("message") || "";
  const errorType = params.get("errorType") || "";
  const leaveDate = params.get("leaveDate") || "";
  const reSignupDate = params.get("reSignupDate") || "";

  useEffect(() => {
    if (error === "true") {
      if (errorType === "CANNOT_RESIGNUP") {
        sessionStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
        navigate(routes.main, {
          state: {
            errorType,
            leaveDate,
            reSignupDate,
          } as LocationState,
          replace: true,
        });
        return;
      }

      message && dispatch(showToast({ message, icon: "error" }));
      navigate(routes.main, { replace: true });
      return;
    }
    switch (type) {
      // SNS 인증 완로
      case "OAUTH_COMPLETED":
        switch (purpose) {
          case "SIGNUP":
            saveAuthInfo({
              code,
              nextPage: routes.main,
              message: name
                ? `가입을 환영합니다! ${name}님`
                : `가입을 환영합니다!`,
            });
            break;
          case "SIGNIN":
            saveAuthInfo({
              code,
              nextPage: routes.main,
              // message: "로그인 되었습니다.",
            });
            break;
          default:
            break;
        }
        break;

      // 본인 인증 완료
      case "SELF_VERIFICATION_COMPLETED":
        switch (purpose) {
          case "SIGNUP":
            if (requireCompleteAction === "true") {
              // 본인인증 후 가입 시작
              dispatch(initSignUp({ purpose, requestId }));
              navigate(routes.acceptTerms, { replace: true });
            } else {
              // 가입 완료
              // 가입 완료시에는 기존 social login token이 만료가 되었기에 기존 토큰 삭제
              sessionStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
              saveAuthInfo({ code, logAppsflyer: true });
            }
            break;

          case "UPDATE_SECURE_PASSWORD":
            if (requireCompleteAction === "true") {
              dispatch(updateSignUp({ purpose, requestId }));
              navigate(routes.authRegisterPassword, { replace: true });
            } else {
              navigate(-1);
              saveAuthInfo({ code, nextPage: routes.editProfile });
            }
            break;

          case "UPDATE_PHONENUMBER":
            saveAuthInfo({
              code,
              nextPage: routes.editProfile,
              message: "휴대폰 번호 변경이 완료되었습니다.",
            });
            break;

          case "DIRECT_VISIT":
            navigate(routes.appraisalReservationComplete, { replace: true });
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  }, [
    params,
    message,
    code,
    type,
    purpose,
    requestId,
    requireCompleteAction,
    error,
    errorType,
    leaveDate,
    reSignupDate,
    navigate,
    dispatch,
  ]);

  const saveAuthInfo = ({
    code,
    nextPage,
    message,
    logAppsflyer,
  }: {
    code: string;
    nextPage?: string;
    message?: string;
    logAppsflyer?: boolean;
  }) => {
    APIS.getAuthToken(code).then(
      ({ data: { success, data, message: errorMessage } }) => {
        if (success) {
          if (logAppsflyer) {
            const decoded = decodeJwt(data.authToken);
            const sub = decoded?.sub || "";
            const ids = sub.split(":");

            const param: AppsFlyerParam = { event: "gb_make_account" };
            if (ids.length > 1) {
              const userId = +ids[1];
              param.value = { customer_user_id: `gb_${userId}` };
              param.userId = userId;
            }

            postMessage({ command: "LOG_APPS_FLYER", param });
          }
          signIn(data.authToken);

          if (message) {
            dispatch(showToast({ message, icon: "success" }));
          }
          if (nextPage) {
            navigate(nextPage, { replace: true });
          }
        } else {
          dispatch(showToast({ message: errorMessage, icon: "error" }));
          navigate(routes.main, { replace: true });
        }
      },
    );
  };
  return null;
}
