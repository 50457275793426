import { RootState } from "@/app/store";
import useCryptoKeyboard from "@/hooks/useCryptoKeyboard";
import { saveSignupStep } from "@/utils/sign";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as APIS from "@/services/apis";
import iconLeft from "@/assets/icons/icon_left.png";
import { ButtonBase } from "@mui/material";
import ic_arrow_left from "@/assets/icons/icon_arrow_left_c.png";
import { useNavigate } from "react-router-dom";
import RNHeader from "@components/common/RNHeader";
import { routes } from "@/routes";
import { showToast } from "@/features/toast/toastSlice";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import { updateSignUp } from "@/features/auth/signUpSlice";

const AuthRegisterPassword = () => {
  const {
    purpose = "SIGNUP",
    requestId,
    termsTypes,
    recommendShop,
    email,
  } = useSelector((state: RootState) => state.signUp);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUpdate = purpose === "UPDATE_SECURE_PASSWORD";
  const { secured, actions: secureActions } = useCryptoKeyboard();
  const [keyArr, setKeyArr] = useState<number[][]>([]);
  const [prevPassword, setPrevPassword] = useState("");

  useLayoutEffect(() => {
    shuffle();
  }, []);

  const shuffle = () => {
    const newArr = Array.from({ length: 10 }, (_, i) => i).sort(
      () => Math.random() - 0.5,
    );

    setKeyArr(Array.from({ length: 4 }).map(() => newArr.splice(0, 3)));
  };

  useEffect(() => {
    //초기 입력값 셋팅
    if (secured.count === 6 && prevPassword) {
      if (secured.last === prevPassword) {
        dispatch(showLoading());
        APIS.postSelfVerificationComplete({
          requestId,
          termsTypes,
          recommandShopId: recommendShop?.id, //서버에서 recommandShopId로 받고있는지 확인.
          securePassword: secured.result,
          email,
        })
          .then(({ data: { success, data, message: errorMessage } }) => {
            if (success) {
              const { code, name } = data;
              const message = isUpdate
                ? "결제 비밀번호 변경이 완료되었습니다."
                : "가입을 환영합니다!";
              dispatch(showToast({ message, icon: "success" }));
              navigate(
                `${routes.authGateway}?code=${code}&name=${name}&type=SELF_VERIFICATION_COMPLETED&purpose=${purpose}`,
                { replace: true },
              );
            } else {
              dispatch(showToast({ message: errorMessage, icon: "error" }));
              setPrevPassword("");
              secureActions.clearAll();
              shuffle();
            }
          })
          .finally(() => {
            dispatch(hideLoading());
          });
      } else {
        dispatch(
          showToast({
            message: "결제 비밀번호가 일치하지 않습니다.\n다시 입력해주세요.",
            icon: "caution",
          }),
        );
        setPrevPassword("");
        secureActions.clearAll();
        shuffle();
      }
    } else {
      if (secured.count === 6 && !prevPassword) {
        setPrevPassword(secured.last);
        secureActions.clearAll();
        shuffle();
      }
    }
  }, [secured.count]);

  useEffect(() => {
    if (purpose === "SIGNUP") saveSignupStep("FIN_NUMBER");
  }, []);

  const handleClickBack = () => {
    navigate(-1);
    dispatch(
      updateSignUp({
        shopCity: { id: 0, name: "" },
        shopDistrictFirst: { id: null, name: "" },
        shopDistrictSecond: { id: null, name: "" },
        recommendShop: { id: null, name: "" },
      }),
    );
  };
  return (
    <>
      {isUpdate && (
        <RNHeader
          visible={false}
          goBack="CUSTOM"
          onGoBack={() => {
            navigate(routes.editProfile);
          }}
        />
      )}
      <div className="keypad_page">
        <div className="keypad_wrap">
          <div className="sub_top">
            <div>
              <img
                src={iconLeft}
                alt="back"
                className="icon_back"
                onClick={handleClickBack}
              />
              <p>{!isUpdate ? "결제 비밀번호 등록" : "결제 비밀번호 변경"}</p>
            </div>
          </div>
          <div className="keypad_top">
            {!prevPassword ? (
              <>
                <h3>결제 비밀번호 입력</h3>
                <p>새로운 비밀번호를 입력해 주세요.</p>
              </>
            ) : (
              <>
                <h3>다시 한 번 입력해 주세요.</h3>
                <p>새로운 결제 비밀번호를 한번 더 입력해 주세요.</p>
              </>
            )}

            {/* 실패시 문구 */}
            {/* <p className="fail">결제 비밀번호가 일치하지 않습니다.</p> */}
            <ul>
              {Array.from({ length: 6 }).map((_, i) => (
                <li
                  className={secured.count > i ? "on" : ""}
                  key={i.toString()}
                />
              ))}
            </ul>
          </div>
          <div className="keypad">
            <table>
              <tbody>
                {keyArr.map((row, i) => {
                  return (
                    <tr key={i.toString()}>
                      {i === 3 && (
                        <td
                          onClick={() => {
                            secureActions.clearAll();
                          }}
                        >
                          <ButtonBase>
                            <p className="on">전체 삭제</p>
                          </ButtonBase>
                        </td>
                      )}

                      {row.map((key, j) => {
                        return (
                          <td key={j.toString()}>
                            <ButtonBase
                              onClick={async () => {
                                secureActions.pressKey(key);
                              }}
                            >
                              {key}
                            </ButtonBase>
                          </td>
                        );
                      })}

                      {i === 3 && (
                        <td
                          onClick={() => {
                            secureActions.removeOne();
                          }}
                        >
                          <ButtonBase>
                            <img src={ic_arrow_left} alt="삭제" />
                          </ButtonBase>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthRegisterPassword;
