import BtnClear2 from "@assets/images/btn_clear2.png";
import IconLeft from "@assets/icons/icon_left.png";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";
import { showToast } from "@/features/toast/toastSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateSignUp } from "@/features/auth/signUpSlice";
import * as APIS from "@/services/apis";
import { useSelector } from "react-redux";
import { RootState } from "@/app/store";
import { saveSignupStep } from "@/utils/sign";
import { validateEmail } from "@/utils/utils";

const AuthRegisterEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { purpose, email } = useSelector((state: RootState) => state.signUp);

  useEffect(() => {
    if (!email) {
      APIS.getSnsEmail().then(({ data: { success, data = "" } }) => {
        if (success) {
          dispatch(updateSignUp({ email: data.split(" ").join("") }));
        }
      });
    }
    if (purpose === "SIGNUP") saveSignupStep("EMAIL");
  }, []);

  const handleDelete = () => {
    dispatch(updateSignUp({ email: "" }));
  };

  const handleChangeEmail = (email: string) => {
    dispatch(updateSignUp({ email: email.split(" ").join("") }));
  };

  const handleClickNext = () => {
    if (!email) {
      dispatch(showToast({ message: "이메일을 입력해주세요.", icon: "error" }));
    } else if (!validateEmail(email)) {
      dispatch(
        showToast({ message: "이메일 형식을 확인해주세요.", icon: "error" }),
      );
    } else {
      // 추천 대리점 입력 화면 이동
      navigate(routes.authRegisterShop);
    }
  };
  return (
    <div>
      <div className="sub_top03">
        <div>
          <img
            src={IconLeft}
            alt="back"
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          <p>이메일</p>
        </div>
      </div>
      <div className="sub_wrap08_2">
        <h3 className="sub_wrap08_2_tt">
          <span className="fc-bl03">이메일</span>을 입력해주세요.
        </h3>
        <div className="email_input">
          <input
            type="text"
            placeholder="이메일을 입력해주세요."
            id="email"
            value={email}
            onChange={({ target: { value } }) => handleChangeEmail(value)}
          />
          <img src={BtnClear2} className="btn_clear" onClick={handleDelete} />
          <label htmlFor="email" style={{ width: "100%" }}>
            <p>이메일</p>
          </label>
        </div>
      </div>

      <div className="sub08_btn">
        <button
          className={`${email === "" ? "off" : "on"}`}
          onClick={handleClickNext}
        >
          확인
        </button>
      </div>
    </div>
  );
};

export default AuthRegisterEmail;
