import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RootState } from "@/app/store";
import { showToast } from "@/features/toast/toastSlice";
import { postMessage, requestNativeLogin } from "@/utils/utils";
import {
  getUserHoldingsInfo,
  postSignOut,
  getAccountInfo,
} from "@/services/apis";
import { signOut } from "@/utils/sign";
import { routes } from "@/routes";
import iconCau from "@/assets/icons/ico_56px_cau.png";
import ic_close from "@assets/icons/icon_x.png";
import ico_orderbook from "@/assets/icons/ico_orderbook_24.png";
import ico_10sec from "@/assets/icons/ico_10sec_24.png";
import ico_notice from "@assets/icons/ico_notice_24.png";
import ico_event from "@/assets/icons/ico_event_24.png";
import ico_guidebook from "@/assets/icons/ico_guidebook_24.png";
import ico_terms from "@/assets/icons/ico_terms_24.png";
import ico_list from "@/assets/icons/ico_list_24.png";
import ico_review2 from "@/assets/icons/ico_review2_24.png";
import ico_deposit from "@/assets/icons/ico_deposit_24.png";
import ico_withdrawal from "@/assets/icons/ico_withdrawal_24.png";
import ico_passbook from "@/assets/icons/ico_passbook_24.png";
import img_setting from "@/assets/icons/img_setting.png";
import ico_won_24 from "@/assets/icons/ico_won_24.png";
import ico_point_24 from "@/assets/icons/ico_pont_24.png";
import ico_gold_24 from "@/assets/icons/ico_gold_24.png";
import ico_silver_24 from "@/assets/icons/ico_silver_24.png";
import ico_safe_24 from "@/assets/icons/ico_safe_24.png";
import ico_faq_24 from "@assets/icons/ico_faq_24px.png";
import ico_Iquestion_24 from "@assets/icons/ico_Iquestion_24px.png";
import ico_btn_down from "@assets/icons/ico_caret_down.png";
import ico_btn_up from "@assets/icons/ico_caret_up.png";
import btnBell from "@/assets/images/btn_bell.png";

import { closeModal, openModal } from "@/features/modal/modalSlice";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import * as APIS from "@/services/apis";
import { setLocalStorageItem } from "@/services/storageService";
import useUserAssetInfo from "./hooks/useUserAssetInfo";

import * as styles from "./style.css";
import AssetItem from "./components/AssetItem";

const LOGIN_TYPE = {
  NON_LOGIN: 0, // 비로그인
  LOGIN: 1, // 로그인 (본인인증 X)
  VERIFICATION: 2, // 로그인 (본인인증 O)
};

const MenuHome: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useSelector((state: RootState) => state.user);
  const {
    role: { LEVEL: userLevel },
    isSigned,
  } = useSelector((state: RootState) => state.auth);

  const [hasDepositAccount, setHasDepositAccount] = useState(false);
  const [hasWithdrawAccount, setHasWithdrawAccount] = useState(false);
  2;
  const [holdings, setHoldings] = useState({
    krw: 0,
    gold: 0,
    silver: 0,
    point: 0,
    acceptableKrw: 0,
    acceptableGold: 0,
    acceptableSilver: 0,
    acceptablePoint: 0,
  });
  const { UserAssetInfo } = useUserAssetInfo();
  console.log(UserAssetInfo);

  const goldAsset = UserAssetInfo?.find(
    (asset) => asset.assetType === "GOLD",
  ) || { totalPrice: 0, averagePrice: 0, earningsRate: 0 };

  const silverAsset = UserAssetInfo?.find(
    (asset) => asset.assetType === "SILVER",
  ) || { totalPrice: 0, averagePrice: 0, earningsRate: 0 };

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isSigned) {
      getUserHoldingsInfo().then(({ data: { success, data = {} } }) => {
        if (success) setHoldings(data);
      });

      getAccountInfo("DEPOSIT").then(({ data: { success, data = {} } }) => {
        // console.log(data);
        if (
          data?.bankAccountStatus === "COMPLETED" &&
          data?.bankAccountNumber
        ) {
          setHasDepositAccount(true);
        }
      });

      getAccountInfo("WITHDRAW").then(({ data: { success, data = {} } }) => {
        if (
          data?.bankAccountStatus === "COMPLETED" &&
          data?.bankAccountNumber
        ) {
          setHasWithdrawAccount(true);
        }
      });
    }
  }, [isSigned]);

  const handleDepositClick = () => {
    if (!hasDepositAccount) {
      dispatch(
        showToast({
          message: "계좌 관리에서 입금 계좌를 먼저 등록하시기 바랍니다.",
          icon: "info",
        }),
      );
      return;
    }
    navigate(routes.deposit);
  };

  const handleWithdrawClick = () => {
    if (!hasWithdrawAccount) {
      dispatch(
        showToast({
          message: "계좌 관리에서 출금 계좌를 먼저 등록하시기 바랍니다.",
          icon: "info",
        }),
      );
      return;
    }
    navigate(routes.withdraw);
  };

  const handleRequestLeave = () => {
    dispatch(showLoading());
    APIS.patchLeaveSocial()
      .then(({ data: { success, message } }) => {
        if (success) {
          if (isSigned) {
            signOut();
            if (window.vreviewWidget) window.vreviewWidget.logout();
          }
          dispatch(
            showToast({
              message: "회원탈퇴가 완료되었습니다.",
              icon: "success",
            }),
          );
        } else {
          dispatch(showToast({ message, icon: "error" }));
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const handleClickLeave = () => {
    dispatch(showLoading());
    APIS.getEnvConfig("USER_RESIGNUP_DAYS")
      .then(({ data: { success, data } }) => {
        success &&
          dispatch(
            openModal(
              <div className="bg_gr_wrap">
                <div className="modal_wrap03">
                  <img src={iconCau} alt="" />
                  <h3>정말 탈퇴하시겠어요?</h3>
                  <p className="fc-rd03">{`탈퇴 후 ${data?.value}일간 가입하실 수 없어요.`}</p>
                  <div className="modal_btn">
                    <button className="bg_rd_btn" onClick={handleRequestLeave}>
                      탈퇴할게요
                    </button>
                    <button
                      className="bg_gr_btn"
                      onClick={() => {
                        dispatch(closeModal());
                      }}
                    >
                      회원 유지할게요
                    </button>
                  </div>
                </div>
              </div>,
            ),
          );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const renderMenu = () => {
    const MenuList = [
      {
        label: "내역",
        list: [
          {
            src: ico_list,
            title: "내역",
            path: routes.tradeHistory,
            LEVEL: LOGIN_TYPE.VERIFICATION,
          },
          {
            src: ico_review2,
            title: "상품 리뷰",
            path: routes.reviewHistory,
            LEVEL: LOGIN_TYPE.VERIFICATION,
          },
        ],
      },
      {
        label: "입출금",
        list: [
          {
            src: ico_deposit,
            title: "입금하기",
            LEVEL: LOGIN_TYPE.VERIFICATION,
            onClick: handleDepositClick,
          },
          {
            src: ico_withdrawal,
            title: "출금하기",
            LEVEL: LOGIN_TYPE.VERIFICATION,
            onClick: handleWithdrawClick,
          },
        ],
      },
      {
        label: "계좌",
        list: [
          {
            src: ico_passbook,
            title: "계좌 관리",
            path: routes.myAccountManagement,
            LEVEL: LOGIN_TYPE.VERIFICATION,
          },
        ],
      },
      {
        label: "서비스",
        list: [
          {
            src: ico_orderbook,
            title: "호가 주문창",
            LEVEL: LOGIN_TYPE.NON_LOGIN,
            onClick: () => {
              navigate(routes.trading, { state: { isFromMenu: true } });
              setLocalStorageItem("tradingAssetType", "GOLD");
            },
          },
          {
            src: ico_10sec,
            title: "10초 감정하기",
            path: routes.appraisalSelectAsset,
            LEVEL: LOGIN_TYPE.NON_LOGIN,
          },
          {
            src: ico_safe_24,
            title: "보관료",
            path: routes.storageFee,
            LEVEL: LOGIN_TYPE.VERIFICATION,
          },
        ],
      },
      {
        label: "안내",
        list: [
          {
            src: ico_notice,
            title: "공지사항",
            path: routes.notice,
            LEVEL: LOGIN_TYPE.NON_LOGIN,
          },
          {
            src: ico_event,
            title: "이벤트",
            path: routes.event,
            LEVEL: LOGIN_TYPE.NON_LOGIN,
          },
          {
            src: ico_guidebook,
            title: "이용가이드",
            onClick: () => {
              postMessage({
                command: "WINDOW_OPEN",
                param: {
                  url: "https://peppered-pen-36f.notion.site/ebdfc84bb4764c738c66ec7c3e9eee9e",
                },
              });
            },
            LEVEL: LOGIN_TYPE.NON_LOGIN,
          },
          {
            src: ico_terms,
            title: "이용약관",
            onClick: () => {
              postMessage({
                command: "WINDOW_OPEN",
                param: {
                  url: "https://peppered-pen-36f.notion.site/1144069ada9e433bb3ebaaa5638233fc",
                },
              });
            },
            LEVEL: LOGIN_TYPE.NON_LOGIN,
          },
        ],
      },
      {
        label: "문의",
        list: [
          {
            src: ico_Iquestion_24,
            title: "1:1 문의",
            path: routes.inquire,
            LEVEL: LOGIN_TYPE.LOGIN,
          },
          {
            src: ico_faq_24,
            title: "자주 묻는 질문",
            onClick: () => {
              postMessage({
                command: "WINDOW_OPEN",
                param: {
                  url: "https://peppered-pen-36f.notion.site/253076f21db64ba59a4ebed4b5b400ee",
                },
              });
            },
            LEVEL: LOGIN_TYPE.NON_LOGIN,
          },
        ],
      },
    ];

    return MenuList.map((menu, index) => {
      const filteredItems = menu.list.filter((item) => item.LEVEL <= userLevel);
      if (filteredItems.length === 0) return null;

      return (
        <div className="shadow88" key={`menu_${index}`}>
          <h3 className="menu_stitle">{menu.label}</h3>
          <div className="menu_list">
            <ul>
              {filteredItems.map((item, idx) => (
                <li
                  key={`list_${idx}`}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick();
                    } else if (item.path) {
                      navigate(item.path);
                    }
                  }}
                >
                  <ButtonBase
                    style={{ width: "100%", justifyContent: "flex-start" }}
                  >
                    <img src={item.src} />
                    <p>{item.title}</p>
                  </ButtonBase>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <header className="sub_header02">
        {isSigned && (
          <img
            className="search"
            src={btnBell}
            alt="notification_icon_image"
            onClick={() => navigate(routes.notification)}
          />
        )}
        <ButtonBase className="menu" onClick={() => navigate(-1)}>
          <img src={ic_close} alt="close_icon" />
        </ButtonBase>
      </header>
      <div className="menu_wrap">
        {userLevel === LOGIN_TYPE.NON_LOGIN ? (
          <h3
            className="menu_mtitle"
            onClick={() => requestNativeLogin(navigate)}
          >
            로그인을 해주세요.
          </h3>
        ) : userLevel === LOGIN_TYPE.LOGIN ? (
          <h3
            className="menu_mtitle"
            onClick={() =>
              navigate(routes.selfVerification, {
                state: { purpose: "SIGNUP" },
              })
            }
          >
            본인인증을 해주세요.
          </h3>
        ) : (
          <>
            <div className="menu_info">
              <h3>{name}</h3>
              <span className="bar"></span>
              <ButtonBase onClick={() => navigate(routes.editProfile)}>
                <img src={img_setting} alt="setting_icon" />내 정보 변경
              </ButtonBase>
            </div>
            <div className={styles.menuAccordion}>
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="menu_checkbox"
                    name="menu_checkbox"
                    className={styles.menuCheckboxHidden}
                    checked={isOpen}
                    onChange={toggleAccordion}
                  />
                  <label htmlFor="menu_checkbox" className={styles.menuLabel}>
                    <h3 className={styles.menuTitle}>사용 가능한 자산</h3>
                    <img
                      className={styles.updownBtn}
                      src={isOpen ? ico_btn_up : ico_btn_down}
                      alt="화살표"
                    />
                  </label>
                  <div
                    className={`${styles.menuContent} ${
                      isOpen ? styles.menuContentExpanded : ""
                    }`}
                  >
                    <div className={styles.menuAcoBox}>
                      <AssetItem
                        iconSrc={ico_won_24}
                        name="원화"
                        unit="원"
                        holdingAmount={holdings.krw}
                        acceptableAmount={holdings.acceptableKrw}
                        showProfit={false}
                      />
                      <AssetItem
                        iconSrc={ico_point_24}
                        name="포인트"
                        unit="p"
                        holdingAmount={holdings.point}
                        acceptableAmount={holdings.acceptablePoint}
                        showProfit={false}
                      />
                      <AssetItem
                        iconSrc={ico_gold_24}
                        name="금"
                        unit="g"
                        holdingAmount={holdings.gold}
                        acceptableAmount={holdings.acceptableGold}
                        asset={goldAsset}
                      />
                      <AssetItem
                        iconSrc={ico_silver_24}
                        name="은"
                        unit="g"
                        holdingAmount={holdings.silver}
                        acceptableAmount={holdings.acceptableSilver}
                        asset={silverAsset}
                      />
                      <img
                        className={`${styles.closeArrow} ${
                          isOpen ? "active" : ""
                        }`}
                        src={ico_btn_up}
                        alt="화살표"
                        onClick={toggleAccordion}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}
        {renderMenu()}
        {isSigned && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ButtonBase
              className="logout_bt"
              onClick={() => {
                if (window.vreviewWidget) window.vreviewWidget.logout();
                postSignOut();
                signOut();
              }}
            >
              로그아웃
            </ButtonBase>
            {userLevel === LOGIN_TYPE.LOGIN && (
              <ButtonBase className="logout_bt" onClick={handleClickLeave}>
                회원탈퇴
              </ButtonBase>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MenuHome;
