import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "@/routes";
import { RootState } from "@/app/store";

import iconLeft from "@assets/icons/icon_left.png";
import btnTop from "@assets/images/btn_top_48px.png";
import imgCaution from "@assets/icons/img_caution_24.png";
import useGoodsDetail from "../hooks/useGoodsDetail";
import * as styles from "../styles.css";
import { openBottomSheet } from "@/features/bottom-sheet/bottomSheetSlice";
import {
  numberWithCommas,
  requestNativeLogin,
  scrollToRoot,
} from "@/utils/utils";

import SelectOption from "./SelectOption";
import { resetPurchaseInfo } from "@/features/purchase/purchaseSlice";
import styled from "@emotion/styled";
import * as APIS from "@/services/apis";
import { closeModal, openModal } from "@/features/modal/modalSlice";
import { hideLoading, showLoading } from "@/features/loading/loadingSlice";
import colors from "@assets/styles/colors.css";
import * as style from "../styles.css";
import ImageSlider from "./ImageSlider";

interface AssetProps {
  acceptableGold: number;
  acceptableKrw: number;
  acceptablePoint: number;
  acceptableSilver: number;
  gold: number;
  krw: number;
  name: string;
  point: number;
  silver: number;
  storageFee: number;
  withdrawKrw: number;
}

const initialAsset: AssetProps = {
  acceptableGold: 0,
  acceptableKrw: 0,
  acceptablePoint: 0,
  acceptableSilver: 0,
  gold: 0,
  krw: 0,
  name: "",
  point: 0,
  silver: 0,
  storageFee: 0,
  withdrawKrw: 0,
};

const BuyGoodsDetail = () => {
  const S3_BASE_URL = import.meta.env.VITE_S3_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { goodsId } = params;
  const auth = useSelector((state: RootState) => state.auth);
  const { GoodsDetailInfo } = useGoodsDetail(goodsId || "");
  const [acceptable, setAcceptable] = useState(initialAsset);
  const [selectedTab, setSelectedTab] = useState<"description" | "review">(
    "description",
  );
  const isSelfVerification = auth.roles.includes("ROLE_SELF_VERIFICATION");
  const isSoldOut = GoodsDetailInfo?.isSoldOut;
  const searchParams = new URLSearchParams(location.search);
  const fromReviewHistory = searchParams.get("from") === "reviewHistory";

  const fetchInfo = () => {
    if (auth.isSigned) {
      dispatch(showLoading());
      APIS.getUserHoldingsInfo()
        .then(({ data: { data } }) => {
          setAcceptable(data);
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
    dispatch(resetPurchaseInfo());
  };

  useEffect(() => {
    fetchInfo();
  }, [auth.isSigned, location]);

  const handleClickBack = () => {
    if (fromReviewHistory) {
      navigate(-1);
    } else {
      navigate(routes.buy);
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleClickPay = () => {
    dispatch(closeModal());
    navigate(routes.commonSecureKeypad, {
      state: {
        type: "STORAGE_FEE",
        payload: {
          totalFeeKrw: `${acceptable?.storageFee}`,
        },
      },
    });
  };

  const handleClickPurchaseBtn = async () => {
    // 로그인 여부 체크
    if (!auth.isSigned) {
      requestNativeLogin(navigate);
      return;
    }

    // 본인인증 체크
    if (!isSelfVerification) {
      navigate(routes.selfVerification);
      return;
    }

    // 보관료 체크
    await fetchInfo();
    if (acceptable?.storageFee > 0) {
      dispatch(
        openModal(
          <div className="bg_gr_wrap">
            <div className="modal_wrap04 bd_bl">
              <h3>
                인출 하시려면
                <br />
                <span className="fc-bl03">
                  보관료 {numberWithCommas(acceptable?.storageFee)}KRW
                </span>
                를
                <br /> 납부해주세요.
              </h3>
              <p>보유하신 KRW에서 자동 납부됩니다. </p>
              <div className="modal_btn">
                <button className="bg_gr_btn" onClick={handleCloseModal}>
                  취소
                </button>
                <button
                  className="bg_bk_btn"
                  onClick={handleClickPay}
                  style={{
                    backgroundColor: colors.buttonColor,
                  }}
                >
                  납부하기
                </button>
              </div>
            </div>
          </div>,
        ),
      );
      return;
    } else {
      dispatch(openBottomSheet());
    }
  };

  // 할인율
  const discountRate = useMemo(() => {
    if (GoodsDetailInfo) {
      if (GoodsDetailInfo?.standardPrice === 0) {
        if (GoodsDetailInfo?.sellingPrice === 0) {
          // 기준 가격과 판매 가격이 모두 0인 경우
          return 0;
        } else {
          // 기준 가격은 0이지만 판매 가격은 0이 아닌 경우
          return 0; // 원래 값은 Infinity
        }
      } else {
        return Math.round(
          ((GoodsDetailInfo?.standardPrice - GoodsDetailInfo?.sellingPrice) /
            GoodsDetailInfo?.standardPrice) *
            100,
        );
      }
    }
  }, [GoodsDetailInfo]);

  const TabContents = useMemo(() => {
    return selectedTab === "description" ? (
      <>
        <div className="detail_info">
          {GoodsDetailInfo?.goodsDetailImages?.map(
            (detailImage: string, index: number) => (
              <img
                src={`${S3_BASE_URL}${detailImage}`}
                key={`detailImage-${index}`}
              />
            ),
          )}
          <div className={styles.commentArea}>
            {GoodsDetailInfo?.comment
              ?.split("\n")
              .map((comment: string, k: number) => {
                return (
                  <span key={k}>
                    {comment}
                    <br />
                  </span>
                );
              })}
          </div>
        </div>
        <img
          src={`${S3_BASE_URL}${GoodsDetailInfo?.goodsDetailInfoImage}`}
          alt="설명 이미지"
        />
      </>
    ) : (
      <div className="prdDetail">
        <div className="vreview-review-board-widget"></div>
      </div>
    );
  }, [goodsId, GoodsDetailInfo, selectedTab]);

  return (
    <div>
      <SelectOption
        standardPrice={GoodsDetailInfo?.standardPrice || 0}
        goodsOptionsInfos={GoodsDetailInfo?.goodsOptionsInfos || []}
      />
      <div className={`sub_top02 ${style.backgRoundWhite}`}>
        <div>
          <img
            src={iconLeft}
            alt=""
            className="icon_back"
            onClick={handleClickBack}
          />
          <p>상품 상세</p>
        </div>
      </div>
      <div className={`sub02_wrap sub02_detail ${style.backgRoundWhite}`}>
        <div className="sub02_detail_wrap">
          <ImageSlider
            images={GoodsDetailInfo?.goodsImages || []}
            isSoldOut={isSoldOut ?? false}
          />
          <div className="sub02_pd_info">
            <p className={`sub02_brand ${isSoldOut && styles.soldOut.text}`}>
              {GoodsDetailInfo?.brand}
            </p>
            <p className={`sub02_name ${isSoldOut && styles.soldOut.text}`}>
              {GoodsDetailInfo?.goodsName}
            </p>
            {isSoldOut ? (
              <div style={{ width: "42.2px" }}>
                <div className={styles.soldOut.korButton}>품절</div>
              </div>
            ) : (
              <>
                <h3 className="sub02_price">
                  <span className="fc-rd01">{discountRate || "00"}%</span>{" "}
                  {numberWithCommas(GoodsDetailInfo?.sellingPrice)}원
                </h3>
                <h3 className={styles.standardPrice}>
                  {numberWithCommas(GoodsDetailInfo?.standardPrice)}원
                </h3>
              </>
            )}
          </div>
          <div className={styles.notiWrap}>
            <img className={styles.imgNoti} src={imgCaution} alt="!" />
            <div className={styles.notiDesc}>
              <span className={styles.notiDescBold}>
                공임비 {numberWithCommas(GoodsDetailInfo?.fee)}원 포함된 금액
              </span>
              입니다.
              <p className={styles.notiSubDesc}>
                ({GoodsDetailInfo?.assetType === "GOLD" ? "금" : "은"} 보유시
                공임비만 지불하시면 됩니다.)
              </p>
            </div>
          </div>
          <div
            className="vreview-product-review-summary"
            vreview-pdp-product-id={goodsId}
          >
            <input type="hidden" vreview-pdp-product-id={goodsId} />
          </div>
          <div className="prdDetail">
            {/* <Bar /> */}
            <div className="vreview-summary-widget"></div>
          </div>
          <div className="sub02_pd_detail">
            <div className="sub02_pd_tab">
              <ul>
                <li
                  className={selectedTab === "description" ? "on" : ""}
                  onClick={() => setSelectedTab("description")}
                >
                  상품 설명
                </li>
                <li
                  className={selectedTab === "review" ? "on" : ""}
                  onClick={() => setSelectedTab("review")}
                >
                  리뷰<span className="vreview-review-count">0</span>개
                </li>
              </ul>
            </div>
            {TabContents}
          </div>
        </div>
        <div className="top_btn02">
          <a>
            <img
              src={btnTop}
              alt=""
              onClick={() => scrollToRoot({ isSmooth: true })}
            />
          </a>
        </div>
      </div>
      <div className={`sub_btn_wrap ${style.backgRoundWhite}`}>
        {isSoldOut ? (
          <button className="sub_wrap02_btn off">품절 되었습니다</button>
        ) : (
          <button
            className="sub_wrap02_btn on"
            onClick={handleClickPurchaseBtn}
            style={{
              backgroundColor: colors.buttonColor,
            }}
          >
            주문하기
          </button>
        )}
      </div>
    </div>
  );
};

const DetailRating = styled.div<{ image: string }>`
  .vreview-review-summary {
    font-size: 20px;
  }
  .vreview-rating {
    width: 48px;
    height: 48px;
    background-image: url(${(props) => props?.image});
    background-size: 100%;
    margin: 0 -10px 0 -14px;
    /* background-color: pink; */
  }
  .vreview-rating svg {
    display: none;
    /* width: 20px;
    height: 20px; */
  }
  img {
    position: absolute;
    margin-left: -15px;
    margin-top: -17px;
    height: 50px;
  }
`;

export default BuyGoodsDetail;
